@import '../styles/background-color.module.scss';
@import '~ethos-design-system/src/components/Media/Media.scss';

.testimonialSlideshow {
  margin: 0 auto;
  overflow: hidden;
}

.testimonialSlideshow .innerSlideShow {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;

  @include for-phone-only {
    &.lpGlobalWinnerTreatment {
      padding-top: 0;
    }
  }
}
.testimonialSlideshowSlider {
  white-space: nowrap;
}

.testimonialSlide {
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 40px;
  margin-bottom: 24px;
}

.testimonialSlideshowTabs {
  text-align: center;
  display: flex;
  gap: 2px;
  margin: auto;
  max-width: 95%;
  @include for-phone-only {
    width: 90%;
    padding: 10px;
  }

  @include for-tablet-and-up {
    width: 500px;
  }
}

.testimonialSlideshowTab {
  height: 3px;
  border-radius: 16px;
  background-color: #dfdfdf;
  flex-basis: 100%;
  overflow: hidden;
}

.testimonialSlideshowTabHover {
  &:hover {
    cursor: pointer;
  }
}

.testimonialSlideshowTab .progressBar {
  width: 0%;
  &.active {
    height: 4px;
    width: 100%;
    background-color: #056257;
    transition: 5s linear;
  }
}

.bodyContent {
  margin-top: 24px;
}

.body {
  white-space: normal;
  word-wrap: break-word;
  margin: auto;
  max-width: 95%;
  @include for-phone-only {
    width: 90%;
    min-height: 160px;
  }

  @include for-tablet-only {
    width: 80%;
    min-height: 140px;
  }

  @include for-laptop-and-up {
    width: 900px;
  }
}

.nameAndDate {
  margin-top: 50px;
}

.nameAndDate .date {
  font-family: 'Theinhardt';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5d5d5d;
}

.logo {
  margin: auto;
  padding-top: 20px;
  @include for-phone-only {
    width: 126px;
  }

  @include for-tablet-and-up {
    width: 152px;
  }
}

.logo .ratingAndReview {
  padding: 5px;
  margin-top: 12px;
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    justify-content: center;
    gap: 12px;
  }
}

.mobileOptimized {
  .body {
    @include for-phone-only {
      min-height: 100px;
    }

    @include for-tablet-only {
      min-height: 80px;
    }
  }
  @include for-phone-and-tablet {
    .nameAndDate {
      margin-top: 0;
    }
    .innerSlideShow {
      padding-top: 10px;
      padding-bottom: 0;
    }
    .testimonialSlideshowTabs {
      width: 55%;
      gap: 8px;
      position: relative;
      padding-top: 11px;
      padding-bottom: 10px;
    }
  }
  @include for-tablet-only {
    .innerSlideShow {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}

.finalExpense {
  border-radius: 0px 0px 0px 160px;

  @include for-phone-only {
    border-radius: 0px 0px 0px 60px;
  }

  .innerSlideShow {
    padding-top: 80px;
    padding-bottom: 110px;

    @include for-phone-only {
      padding-top: 60px;
      padding-bottom: 80px;
    }
  }

  .testimonialSlide {
    margin-bottom: 64px;

    @include for-phone-only {
      margin-bottom: 32px;
    }
  }

  .logo {
    width: var(--imageWidth, 136px);
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 105px;
  }

  .bodyContent {
    margin-top: 32px;

    @include for-phone-only {
      margin-top: 24px;
    }
  }

  .body {
    min-height: initial;

    .bodyCopy {
      color: #272727;
      font-family: Theinhardt;
      font-size: 48px;
      font-weight: 500;
      letter-spacing: -0.48px;

      @include for-phone-only {
        color: rgba(3, 15, 26, 0.8);
        font-family: Theinhardt;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  .nameAndDate {
    margin-top: 32px;

    @include for-phone-only {
      margin-top: 24px;
    }

    .name {
      color: #272727;
      font-size: 28px;
      font-weight: 500;
      letter-spacing: -0.28px;

      @include for-phone-only {
        color: #000;
        font-size: 24px;
        letter-spacing: -0.24px;
      }
    }

    .date {
      color: #727272;
      font-size: 20px;
      font-weight: 400;
      margin-top: 10px;

      @include for-phone-only {
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }
}

.badges {
  border-radius: 0;

  .innerSlideShow {
    padding-top: 100px;
    padding-bottom: 100px;

    @include for-phone-only {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }

  .testimonialSlide {
    margin-bottom: 40px;

    @include for-phone-only {
      margin-bottom: 32px;
    }
  }

  .testimonialSlideshowTabs {
    align-items: center;
    justify-content: center;
    gap: 8px;

    .arrow {
      @include for-phone-only {
        position: static;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        path {
          fill: black;
        }

        &:first-child {
          margin-right: 24px;
        }

        &:last-child {
          margin-left: 24px;
        }
      }
    }

    @include for-tablet-and-up {
      max-width: 630px;
      width: 100%;
    }
  }

  .testimonialSlideshowTab {
    height: 4px;
    width: 120px;

    @include for-phone-only {
      width: 24px;
      flex-basis: initial;
    }
  }

  .logo {
    width: var(--imageWidth, 152px);
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 110px;

    .ratingAndReview {
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0.0075em;
      color: #272727;
      margin-top: 12px;
      padding: 0;
      gap: 8px;
    }
  }

  .bodyContent {
    margin-top: 32px;

    @include for-phone-only {
      margin-top: 24px;
    }
  }

  .body {
    min-height: initial;

    .bodyCopy {
      color: #272727;
      font-family: Theinhardt;
      font-size: 40px;
      font-weight: 500;
      line-height: 55px;
      letter-spacing: -0.01em;
      min-height: 110px;

      @include for-phone-only {
        color: #272727;
        font-family: Theinhardt;
        font-size: 24px;
        font-weight: 500;
        line-height: 34px;
        letter-spacing: 0em;
        min-height: 102px;
      }
    }
  }

  .nameAndDate {
    margin-top: 32px;

    @include for-phone-only {
      margin-top: 24px;
    }

    .name {
      color: #272727;
      font-size: 20px;
      font-weight: 500;

      @include for-phone-only {
        color: #000;
        font-size: 18px;
      }
    }

    .date {
      color: #727272;
      font-size: 16px;
      font-weight: 400;
      margin-top: 10px;

      @include for-phone-only {
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }
}

.additionalRatingsContainer {
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 80px;

  img {
    height: 120px;
    width: auto;
    user-select: none;
  }

  .divider {
    width: 1px;
    height: 100px;
    background-color: #272727;
    opacity: 0.2;
  }

  @include for-phone-only {
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .divider {
      width: 162px;
      height: 1px;
    }

    img {
      height: 100px;
      width: auto;
    }
  }
}

.willsTestimonials {
  .innerSlideShow {
    padding: 48px 0 32px 0;

    @include for-tablet-and-up {
      padding: 48px 0;
    }
  }
}
